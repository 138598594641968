import i18n from '@/localisation/i18n';
import logger from '@/modules/common/services/logger.service';
import { UserAccount } from '@/modules/user-accounts/types/user-accounts';
import { mutations as rootMutations } from '@/store/mutations';
import { AppState } from '@/store/store';
import { ActionHandler } from '@/types/vuex.custom';
import {
  CompanyAccountRequest,
  RawBrokerCompanies,
  normalizeUserAccount,
} from '@/utils/api/broker';
import {
  BorrowerLocate,
  BorrowerLocatePayload,
  normalizeBorrowerLocate,
} from '@/utils/api/locates';
import { PermissionError } from '@/utils/errors/permission-error';
import { Role } from '@/utils/helpers/permissions';
import {
  AuctionEquity,
  AuctionLeakage,
  BespokeAuction,
  ClientConfig,
  ClosedAuction,
  DesktopFeature,
  InvestorProfile,
  LoginPreparePasswordResponse,
  LoginResponse,
  MyAccount,
  OrderTicket,
  RestOperation,
  RfcRequest,
  TradingPermissionOption,
  UXConfig,
  UpdateTraderUserResponse,
  UserNotification,
  createBespokeAuctionInput,
  createOrderTicketInput,
  featureFactory,
  normalizeBespokeAuction,
  normalizeClosedAuction,
  normalizeMyAccount,
  normalizeOrderTicket,
  normalizeUserNotification,
} from '@/utils/helpers/rest';
import { i18nServerMessage } from '@/utils/helpers/rest-response';
import { SocketMessage, SocketNotification } from '@/utils/helpers/socket';
import axios, { AxiosError } from 'axios';

import {
  actions as manualLoansActions,
  mutations as manualLoansMutations,
} from '@/modules/manual-loan/store/store';
import { MarketTimeframes } from '@/modules/market-closed/types/market-closed';
import router from '@/router/router';
import { socketDebouncConfig, socketDebounceTime } from '@/store/store.const';
import { ValueAtRiskResponse, normalizeValueAtRiskResponse } from '@/utils/api/analytics';
import { CounterpartyCredit, normalizeCounterpartyCredit } from '@/utils/api/credits';
import { UptimeService, normalizeUptimeService } from '@/utils/api/uptime';
import { ApiError } from '@/utils/errors/api-error';
import { initSentry } from '@/utils/helpers/sentry';
import { debounce, each, noop } from 'lodash';
import { Store } from 'vuex';

// @TODO should move into a types d.ts for store related interfaces
type AllMutations = typeof rootMutations & typeof manualLoansMutations;
type StoreAction = ActionHandler<AppState, AppState, AllMutations>;
type AllActions = typeof actions & typeof socketActions & typeof manualLoansActions;

/**
 * Export key names as a helper type
 */
export type ActionKeys = keyof AllActions;

export const actions = {
  refreshCurrentTime: function ({ commit }) {
    commit('updateCurrentTimeUTC', new Date());
  } as StoreAction,

  fetchClientConfig: async function (context) {
    try {
      const { data } = await axios.get<{ clientConfig: ClientConfig }>('/api/1/config');
      if (!data.clientConfig) {
        throw new ApiError('server not ready');
      }

      context.commit('updateClientConfig', data.clientConfig);

      // set extra defaults if in demo mode
      if (data.clientConfig.demoMode) {
        const uxConfig: UXConfig = { ...context.state.uxConfig, hasSLAuction: true };
        context.commit('updateUXConfig', uxConfig);
      }
      await context.dispatch('connectToSentry');
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  connectToSentry: function (context) {
    const cfg = context.state.clientConfig;
    if (!context.state.sentryConnected && cfg != null) {
      if (cfg.sentryServerDSN !== '') {
        initSentry(cfg);
        context.commit('SENTRY_CONNECT', true);
      }
    }
  } as StoreAction,

  logout: async function ({ commit, dispatch }) {
    commit('clearCurrentUser');
    await dispatch('cancelPendingSocketHandles');
  } as StoreAction,

  fetchActiveAuctions: async function ({ commit }) {
    try {
      const { data }: { data: { auctions: BespokeAuction[] } } = await axios.get(
        '/api/1/trader/bespoke-auctions/auctions'
      );

      // update 'renderedAt' (used as 'BespokeAuction:key') every time an auction is loaded
      // so that Vue is forced to repaint the order. Without it the order is not
      // repainted when the user edited the order
      data.auctions.forEach((auction: BespokeAuction) => {
        auction.companyOrderTickets = auction.companyOrderTickets.map((ticket) => {
          ticket = normalizeOrderTicket(ticket);
          ticket.renderedAt = new Date();

          return ticket;
        });
        normalizeBespokeAuction(auction);
      });

      commit('updateAuctions', data.auctions);
    } catch (e) {
      if ((e as AxiosError).response?.status === 404) {
        // MM: No current auction. Do nothing
      } else {
        throw new ApiError(i18nServerMessage(e as AxiosError));
      }
    }
  } as StoreAction,

  fetchAuctionsHistory: async function ({ commit }) {
    try {
      const { data } = await axios.get('/api/1/trader/bespoke-auctions/auctions-history');
      const closedAuctions = data.auctions.reverse();
      closedAuctions.forEach((auction: ClosedAuction) => normalizeClosedAuction(auction));

      commit('updateAuctionsHistory', closedAuctions);
    } catch (e) {
      logger.debug('e=' + e);
      if ((e as AxiosError).response?.status === 404) {
        // MM: No current auction. Do nothing
      } else {
        throw new ApiError(i18nServerMessage(e as AxiosError));
      }
    }
  } as StoreAction,

  fetchMyNotifications: async function ({ state, commit }) {
    if (state.loginState.user != null) {
      try {
        const { data } = await axios.get('/api/1/me/notifications');
        data.notifications.forEach((notification: UserNotification) =>
          normalizeUserNotification(notification)
        );
        commit('updateNotifications', data.notifications);
        commit('updateTotalNotificationCount', data.totalCount);
        commit('updateUnreadNotificationCount', data.unreadCount);
      } catch (e) {
        throw new ApiError(i18nServerMessage(e as AxiosError));
      }
    }
  } as StoreAction,

  fetchMyNotificationCounts: async function ({ state, commit }) {
    if (state.loginState.user != null) {
      try {
        const { data } = await axios.get('/api/1/me/notifications/counts');
        commit('updateTotalNotificationCount', data.totalCount);
        commit('updateUnreadNotificationCount', data.unreadCount);
      } catch (e) {
        throw new ApiError(i18nServerMessage(e as AxiosError));
      }
    }
  } as StoreAction,

  markNotificationRead: async function ({ dispatch }, ids: string[]) {
    try {
      await axios.post(
        `/api/1/me/notifications/mark-read`,
        ids.map((id) => {
          return { id, isRead: true };
        })
      );
      await dispatch('fetchMyNotifications');
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  deleteNotifications: async function ({ dispatch }, ids: string[]) {
    try {
      await axios.post(`/api/1/me/notifications/delete`, ids);
      await dispatch('fetchMyNotifications');
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  fetchDesktopFeatures: async function ({ commit }) {
    // @TODO: fetch functions from the server.
    //        the server should triggers this over a socket if there are new function descriptions
    const features: DesktopFeature[] = await [
      featureFactory(
        'desktops.title',
        'traderhome.title',
        'traderhome.description',
        '/dashboard',
        Role.TraderUser
      ),
      featureFactory(
        'desktops.title',
        'seclending.title',
        'seclending.description',
        '/desktop/sec-lending',
        Role.TraderUser
      ),
      featureFactory(
        'desktops.title',
        'auctions.title',
        'auctions.description',
        '/desktop/sec-lending',
        Role.TraderUser
      ),
      featureFactory(
        'auctions.title',
        'startAuction.title',
        'startAuction.description',
        '/desktop/sec-lending',
        Role.TraderUser
      ),
      featureFactory(
        'orders.title',
        'newOrder.title',
        'newOrder.description',
        '/desktop/sec-lending/new-order',
        Role.TraderUser
      ),
      featureFactory(
        'notifications.title',
        'notifications.title',
        'notifications.description',
        '/dashboard/notifications',
        Role.TraderUser
      ),
      featureFactory(
        'settings.title',
        'userSettings.title',
        'userSettings.description',
        '/user-settings',
        Role.TraderUser
      ),
      featureFactory(
        'settings.title',
        'changePassword.title',
        'changePassword.description',
        '/settings/user-account',
        Role.TraderUser
      ),
      featureFactory(
        'settings.title',
        '2faSettings.title',
        '2faSettings.description',
        '/register-2fa',
        Role.TraderUser
      ),
    ];
    commit('updateDesktopFeatures', features);
  } as StoreAction,

  fetchBrokerCompanies: async function ({ commit }) {
    try {
      const { data } = await axios.get<RawBrokerCompanies>('/api/1/broker-admin/companies');
      commit('updateBrokerCompanies', data.companies);
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  createBrokerCompany: async function (_context, company: CompanyAccountRequest) {
    try {
      await axios.post(`/api/1/broker-admin/companies`, company);

      // rely on socket event to reload companies
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  updateBrokerCompany: async function (_context, company: CompanyAccountRequest) {
    try {
      await axios.put(`/api/1/broker-admin/companies/${company.id}`, company);

      // rely on socket event to reload companies
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  deleteBrokerCompany: async function (_context, companyId: string) {
    try {
      await axios.delete(`/api/1/broker-admin/companies/${companyId}`);

      // rely on socket event to reload companies
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  deleteBrokerCompanyLoans: async function (_context, companyId: string) {
    try {
      await axios.delete(`/api/1/broker-admin/companies/${companyId}/loans`);
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  fetchBrokerAdminUsers: async function ({ commit }) {
    try {
      const { data } = await axios.get('/api/1/broker-admin/users');
      data.users.forEach((ua: UserAccount) => normalizeUserAccount(ua));
      commit('updateBrokerUsers', data.users);
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  createBrokerAdminUser: async function (_context, user: UserAccount) {
    try {
      await axios.post(`/api/1/broker-admin/companies/${user.companyID}/users`, user);

      // rely on socket event to reload users
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  updateBrokerAdminUser: async function (_context, user: UserAccount) {
    try {
      await axios.put(`/api/1/broker-admin/users/${user.id}`, user);

      // rely on socket event to reload users
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  deleteBrokerAdminUser: async function (_context, user: UserAccount) {
    try {
      await axios.delete(`/api/1/broker-admin/users/${user.id}`);

      // rely on socket event to reload users
    } catch (e) {
      throw new ApiError(
        i18nServerMessage(e as AxiosError, `${user.emailAddress} cannot be deleted`)
      );
    }
  } as StoreAction,

  disableBrokerAdminUser: async function (_context, user: UserAccount) {
    try {
      await axios.put(`/api/1/broker-admin/users/${user.id}/disable`);

      // rely on socket event to reload users
    } catch (e) {
      throw new ApiError(
        i18nServerMessage(e as AxiosError, `${user.emailAddress} cannot be disabled`)
      );
    }
  } as StoreAction,

  enableBrokerAdminUser: async function (_context, user: UserAccount) {
    try {
      await axios.put(`/api/1/broker-admin/users/${user.id}/enable`);

      // rely on socket event to reload users
    } catch (e) {
      throw new ApiError(
        i18nServerMessage(e as AxiosError, `${user.emailAddress} cannot be enabled`)
      );
    }
  } as StoreAction,

  approveBrokerAdminUser: async function (_context, user: UserAccount) {
    try {
      await axios.put(`/api/1/broker-admin/users/${user.id}/approve`);

      // rely on socket event to reload users
    } catch (e) {
      throw new ApiError(
        i18nServerMessage(e as AxiosError, `${user.emailAddress} cannot be approved`)
      );
    }
  } as StoreAction,

  approveBrokerAdminRoles: async function (_context, user: UserAccount) {
    try {
      await axios.put(`/api/1/broker-admin/users/${user.id}/approve-roles`);

      // rely on socket event to reload users
    } catch (e) {
      throw new ApiError(
        i18nServerMessage(e as AxiosError, `${user.emailAddress} role change cannot be approved`)
      );
    }
  } as StoreAction,

  createTraderAdminUser: async function (_context, user: UserAccount) {
    try {
      await axios.post(`/api/1/trader-admin/users`, user);

      // rely on socket event to reload users
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  updateTraderAdminUser: async function (
    _context,
    payload: {
      user: UserAccount;
      isAdministrator: boolean;
    }
  ): Promise<UpdateTraderUserResponse> {
    try {
      const resp = await axios.put(`/api/1/trader-admin/users/${payload.user.id}`, payload.user);

      // rely on socket event to reload users

      return resp.data;
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  deleteTraderAdminUser: async function (_context, user: UserAccount) {
    try {
      await axios.delete(`/api/1/trader-admin/users/${user.id}`);

      // rely on socket event to reload users
    } catch (e) {
      throw new ApiError(
        i18nServerMessage(e as AxiosError, `${user.emailAddress} cannot be deleted`)
      );
    }
  } as StoreAction,

  disableTraderAdminUser: async function (_context, user: UserAccount) {
    try {
      await axios.put(`/api/1/trader-admin/users/${user.id}/disable`);

      // rely on socket event to reload users
    } catch (e) {
      throw new ApiError(
        i18nServerMessage(e as AxiosError, `${user.emailAddress} cannot be disabled`)
      );
    }
  } as StoreAction,

  enableTraderAdminUser: async function (_context, user: UserAccount) {
    try {
      await axios.put(`/api/1/trader-admin/users/${user.id}/enable`);

      // rely on socket event to reload users
    } catch (e) {
      throw new ApiError(
        i18nServerMessage(e as AxiosError, `${user.emailAddress} cannot be enabled`)
      );
    }
  } as StoreAction,

  fetchTraderAdminUsers: async function ({ commit }) {
    try {
      const { data } = await axios.get('/api/1/trader-admin/users');
      data.users.forEach((ua: UserAccount) => normalizeUserAccount(ua));
      commit('updateCompanyUsers', data.users);
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,
  fetchTraderAdminCounterpartyCredits: async function ({ commit }) {
    try {
      const { data } = await axios.get('/api/1/trader-admin/counterparty-credits');
      data.credits.forEach((cc: CounterpartyCredit) => normalizeCounterpartyCredit(cc));
      commit('updateCounterpartyCredits', data.credits);
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,
  fetchAdminUptimeServices: async function ({ state, commit }) {
    try {
      // an admin has the `broker-admin`, `broker-user` or `trader-admin` or `config-admin` role!
      // `broker-admin` and `broker-user` share the same endpoint and `trader-admin` and `config-admin` share the other
      const adminRole =
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
        state.loginState.user!.roles.includes('trader-admin') ||
        state.loginState.user!.roles.includes('config-admin')
          ? 'trader-admin'
          : 'broker-user';

      const { data } = await axios.get(`/api/1/${adminRole}/uptime/services`);
      data.uptimeServices.forEach((us: UptimeService) => normalizeUptimeService(us));

      commit('updateUptimeServices', data.uptimeServices);
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,
  createInvestorProfile: async function ({ dispatch }, profile: InvestorProfile) {
    try {
      await axios.post(`/api/1/broker-admin/investor-profiles`, profile);

      // reload profiles
      await dispatch('fetchAdminInvestorProfiles');
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  updateInvestorProfile: async function ({ dispatch }, profile: InvestorProfile) {
    try {
      await axios.put(`/api/1/broker-admin/investor-profiles/${profile.id}`, profile);

      // reload profiles
      await dispatch('fetchAdminInvestorProfiles');
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  deleteInvestorProfile: async function ({ dispatch }, profile: InvestorProfile) {
    try {
      await axios.delete(`/api/1/broker-admin/investor-profiles/${profile.id}`);

      // reload profiles
      await dispatch('fetchAdminInvestorProfiles');
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError, `${profile.label} cannot be deleted`));
    }
  } as StoreAction,

  fetchAdminInvestorProfiles: async function ({ commit }) {
    try {
      const { data } = await axios.get('/api/1/broker-admin/investor-profiles');
      commit('updateInvestorProfiles', data.profiles);
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  fetchTraderInvestorProfiles: async function ({ commit }) {
    try {
      const { data } = await axios.get('/api/1/trader/investor-profiles');
      commit('updateInvestorProfiles', data.profiles);
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  fetchTradingPermissionOptions: async function ({ commit }) {
    try {
      const { data } = await axios.get<{ options: TradingPermissionOption[] }>(
        '/api/1/trader/trading-permission-options'
      );
      commit('updateTradingPermissionOptions', data.options);
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  refreshCurrentUser: async function ({ dispatch }) {
    await dispatch('fetchCurrentUser');
  } as StoreAction,

  fetchCurrentUser: async function ({ commit }) {
    let user: MyAccount;

    try {
      const { data } = await axios.get('/api/1/me');
      user = data.user;
      normalizeMyAccount(user);

      commit('updateCurrentUser', user);
    } catch (err) {
      if (
        (err as AxiosError).response?.status == 401 ||
        (err as AxiosError).response?.status == 403
      ) {
        throw new PermissionError(i18nServerMessage(err as AxiosError));
      } else {
        throw new ApiError(i18nServerMessage(err as AxiosError));
      }
    }
  } as StoreAction,

  enableEquity: async function (_context, eq: AuctionEquity) {
    try {
      await axios.put(`/api/1/broker-admin/equities/${eq.id}/enable`);
    } catch (e) {
      throw new ApiError(
        i18nServerMessage(e as AxiosError, `${eq.ticker} [${eq.cusip}] cannot be enabled`)
      );
    }
  } as StoreAction,

  disableEquity: async function (_context, eq: AuctionEquity) {
    try {
      await axios.put(`/api/1/broker-admin/equities/${eq.id}/disable`);
    } catch (e) {
      throw new ApiError(
        i18nServerMessage(e as AxiosError, `${eq.ticker} [${eq.cusip}] cannot be disabled`)
      );
    }
  } as StoreAction,

  restrictEquity: async function (_context, eq: AuctionEquity) {
    try {
      await axios.put(`/api/1/broker-admin/equities/${eq.id}/restrict`);
    } catch (e) {
      throw new ApiError(
        i18nServerMessage(e as AxiosError, `${eq.ticker} [${eq.cusip}] cannot be restricted`)
      );
    }
  } as StoreAction,

  unrestrictEquity: async function (_context, eq: AuctionEquity) {
    try {
      await axios.put(`/api/1/broker-admin/equities/${eq.id}/unrestrict`);
    } catch (e) {
      throw new ApiError(
        i18nServerMessage(e as AxiosError, `${eq.ticker} [${eq.cusip}] cannot remove restriction`)
      );
    }
  } as StoreAction,

  fetchCurrent2FA: async function ({ commit }) {
    try {
      const { data } = await axios.get('/api/1/me/settings/tfa');
      commit('update2FASettings', data.settings);
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  submitBespokeAuction: async function (
    { dispatch },
    auction: BespokeAuction
  ): Promise<BespokeAuction> {
    const input = createBespokeAuctionInput(auction);
    try {
      const { data }: { data: { auction: BespokeAuction } } = await axios.post(
        `/api/1/trader/bespoke-auctions/auctions`,
        input
      );

      // trigger fetching updated list of auctions
      await dispatch('fetchActiveAuctions');

      // normalize in place
      normalizeBespokeAuction(data.auction);

      return data.auction;
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  submitOrderTicket: async function ({ dispatch }, ticket: OrderTicket): Promise<OrderTicket> {
    const input = createOrderTicketInput(ticket);

    try {
      // JSON.stringify() automatically convert dates to ISO 8601
      const { data } = await axios.post(
        `/api/1/trader/bespoke-auctions/auction/${ticket.auctionId}/ticket`,
        input
      );

      await dispatch('fetchActiveAuctions');
      return data.ticket;
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  updateOrderTicket: async function ({ dispatch }, ticket: OrderTicket): Promise<OrderTicket> {
    const input = createOrderTicketInput(ticket);

    try {
      // JSON.stringify() automatically convert dates to ISO 8601
      const { data } = await axios.put(`/api/1/trader/bespoke-auctions/ticket/${ticket.id}`, input);

      await dispatch('fetchActiveAuctions');
      return data.ticket;
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  deleteAuctionTicket: async function (
    { dispatch },
    payload: { ticketId: string; leakage: AuctionLeakage }
  ) {
    try {
      await axios.put(
        `/api/1/trader/bespoke-auctions/ticket/${payload.ticketId}/delete`,
        payload.leakage
      );

      await dispatch('fetchActiveAuctions');
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  checkoffAuction: async function ({ dispatch }, auction: ClosedAuction) {
    try {
      await axios.put(`/api/1/trader/bespoke-auctions/auction/${auction.id}/check-off`);

      // reload auctions, the auction should move to the other list
      await dispatch('fetchAuctionsHistory');
    } catch (e) {
      throw new ApiError(
        i18nServerMessage(
          e as AxiosError,
          `${auction.equity.ticker}' [${auction.equity.cusip}] cannot be checked off`
        )
      );
    }
  } as StoreAction,

  executeAuction: async function (_context, auctionId) {
    try {
      await axios.post(`/api/1/trader/bespoke-auctions/auction/${auctionId}/execute`);
      // ignore result
      //   auctions will be refreshed by ws event when they have been executed
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  fetchBorrowerLocates: async function ({ commit }) {
    try {
      const { data } = await axios.get<{ locates: BorrowerLocate[] }>('/api/1/borrower/locates');

      data.locates.forEach(normalizeBorrowerLocate);

      commit('updateBorrowerLocates', data.locates);
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  submitBorrowerLocate: async function (
    { dispatch },
    payload: {
      locate: BorrowerLocate;
      crudAction: RestOperation;
    }
  ) {
    if (payload.locate.equity == null) {
      return;
    }

    const submit: BorrowerLocatePayload = {
      equityId: payload.locate.equity.id,
      quantity: payload.locate.quantity,
    };

    try {
      switch (payload.crudAction) {
        case RestOperation.Create:
          await axios.post(`/api/1/borrower/locates`, submit);
          break;
        default:
          throw new Error(`submitBorrowerLocate: operation '${payload.crudAction}' not supported`);
      }

      await dispatch('fetchBorrowerLocates');
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  getValueAtRiskCalculation: async function (_, file: File): Promise<ValueAtRiskResponse> {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const { data } = await axios.post<ValueAtRiskResponse>(
        '/api/1/analytics/var/calc',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      normalizeValueAtRiskResponse(data);

      return data;
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,

  confirmIdentity: async function (_context, resetCode: string): Promise<LoginResponse> {
    try {
      const { data } = await axios.post('/api/1/login-confirm-identity', { encSecret: resetCode });
      return data;
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError, 'invalidResetCode'));
    }
  } as StoreAction,

  requestPasswordReset: async function (_context, loginEmail: string) {
    try {
      await axios.post('/api/1/login-lost-password', { email: loginEmail });
    } catch (e) {
      throw new ApiError(
        i18nServerMessage(
          e as AxiosError,
          i18n.t('loginResetPasswordEmailFailed', { emailAddress: loginEmail }) as string
        )
      );
    }
  } as StoreAction,

  changeLoginPassword: async function (
    _context,
    payload: { oldHashedPassword: string; hashedPassword: string }
  ) {
    try {
      await axios.post('/api/1/login-change-password', {
        oldPassword: payload.oldHashedPassword,
        newPassword: payload.hashedPassword,
      });
    } catch (e) {
      throw new ApiError(
        i18nServerMessage(e as AxiosError, i18n.t('login-password-not-available') as string)
      );
    }
  } as StoreAction,

  prepareNewLoginPassword: async function (
    _context,
    encSecret: string
  ): Promise<LoginPreparePasswordResponse> {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await axios.post('/api/1/login-prepare-password', {
        encSecret: encSecret,
      });
      return data;
    } catch (e) {
      throw new ApiError(
        i18nServerMessage(e as AxiosError, i18n.t('loginResetPasswordEmailExpired') as string)
      );
    }
  } as StoreAction,

  confirm2FALogin: async function (
    _context,
    payload: { email: string; tfaCode: string }
  ): Promise<LoginResponse> {
    try {
      const { data } = await axios.post('/api/1/login-confirm-2fa', {
        email: payload.email,
        tfaCode: payload.tfaCode,
      });
      return data;
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError, 'invalid2FACode'));
    }
  } as StoreAction,

  enable2FALogin: async function (_context, tfaCode: string) {
    try {
      await axios.put('/api/1/me/settings/tfa/enable', { tfaCode });
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError, 'enabled2FA.error'));
    }
  } as StoreAction,

  disable2FALogin: async function (_context, tfaCode: string) {
    try {
      await axios.put('/api/1/me/settings/tfa/disable', { tfaCode });
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError, 'disabled2FA.error'));
    }
  } as StoreAction,

  lost2FADevice: async function (_context, loginEmail: string) {
    try {
      await axios.post('/api/1/login-lost-device', { email: loginEmail });
    } catch (e) {
      throw new ApiError(
        i18nServerMessage(
          e as AxiosError,
          i18n.t('loginResetDeviceEmailFailed', { emailAddress: loginEmail }) as string
        )
      );
    }
  } as StoreAction,

  reset2FALogin: async function (_context, encSecret: string) {
    try {
      await axios.post('/api/1/login-reset-2fa', { encSecret: encSecret });
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError, 'invalidResetCode'));
    }
  } as StoreAction,

  submitWish: async function (_context, rfcRequest: RfcRequest) {
    try {
      await axios.post(`/api/1/me/rfc`, rfcRequest);
    } catch (e) {
      throw new ApiError(i18nServerMessage(e as AxiosError));
    }
  } as StoreAction,
};

export const socketActions = {
  cancelPendingSocketHandles: function () {
    // find just the debounced socket actions and cancel them if they are pending
    each(socketActions, (action) => {
      if ('cancel' in action) {
        action.cancel();
      }
    });
  } as StoreAction,

  refreshClientConfig: debounce(
    function (this: Store<AppState>, { dispatch }, _sm: SocketMessage<SocketNotification>) {
      void dispatch('fetchClientConfig').catch(noop);
    } as StoreAction,
    socketDebounceTime,
    socketDebouncConfig
  ),

  refreshNotifications: debounce(
    function ({ commit, dispatch }, sm: SocketMessage<SocketNotification>) {
      void dispatch('fetchMyNotificationCounts').catch(noop);
      if (sm.payload.notificationRefID != null) {
        // There is a new notification
        // Not just an update of an existing notification
        commit('updateLastNotification', sm);
      }
    } as StoreAction,
    socketDebounceTime,
    socketDebouncConfig
  ),

  refreshOpenAuctions: debounce(
    function ({ dispatch }, _payload: SocketMessage<string>) {
      void dispatch('fetchActiveAuctions').catch(noop);
    } as StoreAction,
    socketDebounceTime,
    socketDebouncConfig
  ),

  refreshClosedAuctions: debounce(
    function ({ dispatch }, _payload: SocketMessage<string>) {
      void dispatch('fetchAuctionsHistory').catch(noop);
    } as StoreAction,
    socketDebounceTime,
    socketDebouncConfig
  ),

  refreshLenderLoan: function ({ commit }, msg: SocketMessage<number>) {
    commit('updateLenderLoanSocketEvent', msg.payload);
  } as StoreAction,

  refreshBorrowerLoan: function ({ commit }, msg: SocketMessage<number>) {
    commit('updateBorrowerLoanSocketEvent', msg.payload);
  } as StoreAction,

  refreshBorrowerOrders: debounce(
    function ({ dispatch }, _payload: SocketMessage<string>) {
      void dispatch('fetchBorrowerOrders').catch(noop);
    } as StoreAction,
    socketDebounceTime,
    socketDebouncConfig
  ),

  refreshLenderOrders: debounce(
    function ({ dispatch }, _payload: SocketMessage<string>) {
      void dispatch('fetchLenderOrders').catch(noop);
    } as StoreAction,
    socketDebounceTime,
    socketDebouncConfig
  ),

  refreshBrokerLoan: function ({ commit }, msg: SocketMessage<number>) {
    commit('updateBrokerLoanSocketEvent', msg.payload);
  } as StoreAction,

  refreshBrokerLenderOrders: debounce(
    function ({ dispatch }, _payload: SocketMessage<string>) {
      void dispatch('fetchBrokerLenderOrders').catch(noop);
    } as StoreAction,
    socketDebounceTime,
    socketDebouncConfig
  ),

  refreshBrokerBorrowerOrders: debounce(
    function ({ dispatch }, _payload: SocketMessage<string>) {
      void dispatch('fetchBrokerBorrowerOrders').catch(noop);
    } as StoreAction,
    socketDebounceTime,
    socketDebouncConfig
  ),

  // incoming socket event
  userDisabled: function (context, _payload: SocketMessage<string>) {
    context.commit('clearCurrentUser');
    // @TODO Router is not installed on the instance of Vue we have under this._vm
    // we should inject the router by another means
    // void this._vm?.$router.push({ name: 'home' }).catch(noop);
    void router.push({ name: 'home' }).catch(noop);
  } as StoreAction,

  // incoming socket event
  refreshMarketTimeframes: function (context, message: SocketMessage<MarketTimeframes>) {
    context.commit('updateMarketTimeframes', message.payload);
  } as StoreAction,

  // incoming socket event
  refreshCompanies: function (context) {
    const roles = context.state.loginState.user?.roles || [];
    if (roles.includes('broker-admin')) {
      void context.dispatch('fetchBrokerCompanies');
    }
  } as StoreAction,

  // incoming socket event
  refreshUsers: function (context) {
    const roles = context.state.loginState.user?.roles || [];
    if (roles.includes('broker-admin')) {
      void context.dispatch('fetchBrokerAdminUsers');
    } else if (roles.includes('trader-admin')) {
      void context.dispatch('fetchTraderAdminUsers');
    }
  } as StoreAction,

  // incoming socket event
  refreshFrontendHash: function (context, message: SocketMessage<string>) {
    context.commit('updateFrontendHash', message.payload);
  } as StoreAction,

  refreshOmsOrders: function ({ commit }, msg: SocketMessage<{ orderRef: string }>) {
    commit('updateMarketplaceOrdersEvent', msg.payload.orderRef);
  } as StoreAction,

  termLoanChanged: function ({ commit }, msg: SocketMessage<string>) {
    commit('updateTermLoansEvent', msg.payload);
  } as StoreAction,
};
