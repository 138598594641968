import { Equity } from '@/modules/common/types/api';
import { manualLoansStore } from '@/modules/manual-loan/store/store';
import { UserAccount } from '@/modules/user-accounts/types/user-accounts';
import { actions, socketActions } from '@/store/actions';
import { storeGetters as getters } from '@/store/getters';
import { mutations } from '@/store/mutations';
import { BorrowerOpenLoan } from '@/utils/api/borrower';
import { CompanyAccount } from '@/utils/api/broker';
import { CounterpartyCredit } from '@/utils/api/credits';
import { BorrowerLocate } from '@/utils/api/locates';
import { UptimeService } from '@/utils/api/uptime';
import PermissionValidator from '@/utils/helpers/permissions';
import {
  AuctionEquity,
  BespokeAuction,
  ClientConfig,
  ClosedAuction,
  DesktopFeature,
  InvestorProfile,
  MyAccount,
  TradingPermissionOption,
  UXConfig,
  User2FASettings,
  UserNotification,
} from '@/utils/helpers/rest';
import { SocketMessage } from '@/utils/helpers/socket';
import { TradingPermission } from '@/utils/helpers/trading-permissions';
import { merge } from 'lodash';
import { VueRouter } from 'vue-router/types/router';
import Vuex, { Store } from 'vuex';

export interface SocketEvents {
  marketplace: {
    orders: { orderRef: string } | null;
  };
}

export interface SocketEvents {
  termLoans: { termContractDisplayId: string } | null;
  openLoans: {
    lenderLoan: { loanId: string } | null;
    borrowerLoan: { loanId: string } | null;
    brokerLoan: { loanId: string } | null;
  };
}

export interface SessionState {
  socketEvents: SocketEvents;
  loginState: LoginState;
  uxConfig: UXConfig;
  notifications: UserNotification[];
  unreadNotificationCount: number;
  totalNotificationCount: number;
  desktopFeatures: DesktopFeature[];
  lastNotification: SocketMessage<unknown> | null;
  auctions: BespokeAuction[];
  auctionsHistory: ClosedAuction[];

  borrowerShowAllLoans: boolean;
  borrowerOpenLoans: BorrowerOpenLoan[];

  lastVisitedSymbolOverview: Equity | null;

  borrowerLocates: BorrowerLocate[];

  companies: CompanyAccount[];
  users: UserAccount[];
  companyUsers: UserAccount[];
  tradingPermissionOptions: TradingPermissionOption[];
  investorProfiles: InvestorProfile[];
  equities: AuctionEquity[];
  counterpartyCredits: CounterpartyCredit[];
  uptimeServices: UptimeService[];
  socketWLToken: string | null;
}

export interface AppState extends SessionState {
  clientConfig: ClientConfig | null;
  frontendHash: string;
  currentTimeUTC: Date;
  socketConnected: boolean;
  socketReconnecting: boolean;
  sentryConnected: boolean;
}

export interface LoginState {
  user: MyAccount | null;
  tfa: User2FASettings | null;
  permissionValidator: PermissionValidator | null;
  tradingPermissions: TradingPermission;
}

const defaultLoginState: LoginState = {
  user: null,
  tfa: null,
  permissionValidator: null,
  tradingPermissions: TradingPermission.DisabledPermissions,
};
const defaultFrontendHash = '';
const defaultUxConfig: UXConfig = {
  hasGlobalSearch: false,
  hasSLAuction: false,
  forceMarketTimeframesCheckingEvenWhenDisabled: false,
  forceMarketTimeframesClosed: false,
  dismissedPreEstablishedInfo: false,
};
/**
 * Creates a new empty app state for the Store
 */
function newAppState(sessionState: SessionState): AppState {
  return merge(
    {},
    {
      clientConfig: null,
      frontendHash: defaultFrontendHash,
      socketConnected: false,
      socketReconnecting: false,
      sentryConnected: false,
      currentTimeUTC: new Date(),
      ...sessionState,
    }
  );
}

/**
 * Creates a new empty Session state for the Store.
 * Session state is a subset of AppState, specific to a user's session
 */
export function newSessionState(initialConfig: Partial<SessionState> = {}): SessionState {
  let uxConfig = {};
  try {
    uxConfig = JSON.parse(sessionStorage.uxConfig);
  } catch (e) {
    // ignore any errors, in that case we just use the default
  }

  return merge<Record<string, never>, SessionState>(
    {},
    {
      socketEvents: {
        marketplace: {
          orders: null,
        },
        termLoans: null,
        openLoans: {
          lenderLoan: null,
          borrowerLoan: null,
          brokerLoan: null,
        },
      },
      loginState: defaultLoginState,
      uxConfig: { ...defaultUxConfig, ...uxConfig },
      notifications: [],
      totalNotificationCount: 0,
      unreadNotificationCount: 0,
      desktopFeatures: [],
      lastNotification: null,
      auctions: [],
      auctionsHistory: [],

      borrowerShowAllLoans: false,
      borrowerOpenLoans: [],

      lastVisitedSymbolOverview: null,

      // START_DEMO {{
      borrowerLocates: [],
      // END_DEMO }}

      companies: [],
      users: [],
      companyUsers: [],
      tradingPermissionOptions: [],
      investorProfiles: [],
      equities: [],
      counterpartyCredits: [],
      uptimeServices: [],
      socketWLToken: null,
      ...initialConfig,
    }
  );
}

// lazily initialized singleton by createStore()
export let store: Store<AppState>;

export function createStore(
  _$router: VueRouter,
  initialState: Partial<AppState> = {}
): Store<AppState> {
  const initialConfig = merge({}, initialState);

  return (store = new Vuex.Store<AppState>({
    strict: true,
    state: newAppState(newSessionState(initialConfig)),
    mutations,
    actions: { ...socketActions, ...actions },
    getters,
    modules: {
      manualLoans: manualLoansStore,
    },
  }));
}
